@import "Themes/principalTheme.scss"; /* You can add global styles to this file, and also import other style files */
@include angular-material-theme($light-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.table-default {
  width: 100%;
  text-align: center;

  th.mat-header-cell {
    background-color: #3b4966;
    color: #ebecee;
  }
  tr.mat-row {
    &:nth-child(even) {
      background-color: #ebecee;
    }
    &:nth-child(odd) {
      background-color: white;
    }
  }
  td.mat-cell {
    border-right: solid;
    border-right-color: #dedee8;
    text-align: center;
  }

  th.mat-header-cell {
    border-right-color: #dedee8;
    border-right: solid;
    text-align: center;
  }
  .mat-sort-header-container {
    display: flex !important;
    justify-content: center !important;
  }
  .mat-sort-header-arrow {
    color: #fff !important;
  }
}

.sidenav {
  width: 200px;
  background-color: #2d568f;
}
.sidenav_toolbar {
  background-color: rgba(39, 75, 124, 0.92);
}
.label-size {
  font-size: medium;
}
.mat-expansion-indicator::after {
  color: white;
}

.snackbar {
  background-color: #00c3cb;
  color: white;
}
